export default {
    // add color palettes etc
    palette: {
        headings: ["2.25rem", "1.62rem", "1.38rem", "1rem", "0.85rem"],
    },
    colors: {
        primary: "magenta",
    },
    buttons: {
        helper: {
            backgroundColor: "magenta",
            padding: "0.2rem 0.5rem",
            cursor: "pointer",
        },
    },

}