// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-0-callback-js": () => import("./../../../src/pages/auth0_callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-vote-js": () => import("./../../../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */),
  "component---src-pages-widget-admin-js": () => import("./../../../src/pages/widget_admin.js" /* webpackChunkName: "component---src-pages-widget-admin-js" */)
}

